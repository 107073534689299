import React, { Component } from "react";
import "./Contact.css";
import PageTitle from "../shared/PageTitle";
import ContactForm from "./contact_form/ContactForm";
import ContactPoints from "./contact_points/ContactPoints";

export default class Contact extends Component {
  render() {
    return (
      <section className="Contact" id="Contact">
        <PageTitle>Kontakt</PageTitle>

        <ContactPoints />

        <div className="Contact__formWrapper">
          <ContactForm />
        </div>
      </section>
    );
  }
}
