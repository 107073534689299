import React, { Component } from "react";

import avatar from "../../assets/me.png";
import "./AboutMe.scss";

export default class AboutMe extends Component {
  render() {
    return (
      <section className="AboutMe" id="AboutMe">
        <div className="AboutMe__content">
          <div className="AboutMe__text">
            <h4 className="AboutMe__short">
              Ich bin Krzysztof Zibur,{" "}
              <div className="AboutMe__jobtitle">Softwareentwickler</div>
              aus Aachen, Deutschland.
            </h4>
            <div>Auf der Suche nach neuen Herausforderungen.</div>
            <br />
            <div>
              <div>
                <strong>10+ Jahre</strong> Entwicklungserfahrung
              </div>
              <br />
              <div>
                Abschlüsse: <br />
                <strong>
                  B.Sc. Scientific Programming, <br />
                  Mathematisch technischer Softwareentwickler
                </strong>
              </div>
            </div>
          </div>
          <div className="AboutMe__avatar">
            <img src={avatar} alt="Krzysztof Zibur" />
          </div>
        </div>
      </section>
    );
  }
}
