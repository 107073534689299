import React, { Component } from "react";
import "./Button.scss";

export default class Button extends Component {
  render() {
    const { children, onClick } = this.props;
    return (
      <div className="Button">
        <button className="Button__element" type="button" onClick={onClick}>
          {children}
        </button>
      </div>
    );
  }
}
