import React, { Component } from "react";
import "./TextField.scss";

export default class TextField extends Component {
  render() {
    const { label, value, onChange } = this.props;
    return (
      <div className="TextField">
        <label className="TextField__label">{label}</label>
        <input
          className="TextField__input"
          placeholder={label}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  }
}
