import React, { Component } from "react";
import "./ContactForm.scss";
import TextField from "../../shared/uikit/TextField";
import TextArea from "../../shared/uikit/TextArea";
import Button from "../../shared/uikit/Button";
import axios from "axios";

const isEmailValid = email => /\S+@\S+\.\S+/.test(email);

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      isValid: true,
    };
  }

  setName(event) {
    const { value } = event.target;
    this.setState({
      name: value
    });
  }

  setEmail(event) {
    const { value } = event.target;
    this.setState({
      email: value
    });
  }

  setMessage(event) {
    const { value } = event.target;
    this.setState({
      message: value
    });
  }

  validateAll() {
    const { name, email, message } = this.state;

    var isValid = true;

    const trimmedName = name.trim();
    const trimmedEmail = email.trim();
    const trimmedMessage = message.trim();

    if (trimmedName === "" || trimmedName.length < 3) {
      isValid = false;
    }

    if (
      trimmedEmail === "" ||
      trimmedEmail.length < 3 ||
      isEmailValid(trimmedEmail) === false
    ) {
      isValid = false;
    }

    if (trimmedMessage === "" || trimmedMessage.length < 3) {
      isValid = false;
    }

    this.setState({
      isValid
    });

    return isValid;
  }

  submitForm(val) {
    if (this.validateAll()) {

      const { name, email, message } = this.state;
    const trimmedName = name.trim();
    const trimmedEmail = email.trim();
    const trimmedMessage = message.trim();

      axios
        .post(
          "http://api.zibur.de/send",
          {
            name: trimmedName,
            email: trimmedEmail,
            message: trimmedMessage,
          },
        )
        .then(function(response) {
          console.log(response);
        });
    }
  }

  render() {
    return (
      <form className="ContactForm">
        <NotValidText visible={this.state.isValid === false} />

        <div className="ContactForm__address">
          <TextField
            label="Name"
            value={this.state.name}
            onChange={this.setName.bind(this)}
          />
          <TextField
            label="E-Mail"
            value={this.state.email}
            onChange={this.setEmail.bind(this)}
          />
        </div>

        <TextArea
          label="Nachricht"
          value={this.state.message}
          onChange={this.setMessage.bind(this)}
        />

        <div className="ContactForm__buttonWrapper">
          <Button onClick={this.submitForm.bind(this)}>Absenden</Button>
        </div>
      </form>
    );
  }
}

const NotValidText = ({ visible }) => {
  if (visible) {
    return (
      <div className="NotValidText">Bitte füllen Sie alle Felder aus.</div>
    );
  }

  return null;
};
