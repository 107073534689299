import React, { Component } from "react";
import "./ContactPoint.scss";

export default class ContactPoints extends Component {
  render() {
    return (
      <div className="ContactPoints">
        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="fab fa-linkedin-in" />
          </div>
          <a
            className="ContactPoints__linkedin"
            href="https://www.linkedin.com/in/krzysztof-zibur-2a596418a/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Krzysztof Zibur
          </a>
        </div>
        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="fab fa-xing" />
          </div>
          <a
            className="ContactPoints__xing"
            href="https://www.xing.com/profile/Krzysztof_Zibur"
            target="_blank"
            rel="noreferrer noopener"
          >
            Krzysztof_Zibur
          </a>
        </div>
        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="fab fa-twitter" />
          </div>
          <a
            className="ContactPoints__twitter"
            href="https://twitter.com/KrzysztofZibur"
            target="_blank"
            rel="noreferrer noopener"
          >
            @KrzysztofZibur
          </a>
        </div>
        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="fab fa-github" />
          </div>
          <a
            className="ContactPoints__xing"
            href="https://github.com/zibur"
            target="_blank"
            rel="noreferrer noopener"
          >
            zibur
          </a>
        </div>

        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="far fa-envelope" />
          </div>
          <div className="ContactPoints__email">contact@zibur.de</div>
        </div>
        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="fas fa-phone" />
          </div>
          <div className="ContactPoints__phone">+49 (0)176 456 533 46</div>
        </div>
        <div className="ContactPoints__point">
          <div className="ContactPoints__icon">
            <i className="fas fa-map-pin" />
          </div>
          <div className="ContactPoints__address">
            Am Alten Bahndamm 4<br />
            52072 Aachen, Deutschland
          </div>
        </div>
      </div>
    );
  }
}

// <div className="Toolbar__social">
//   <a
//     className="Toolbar_socialLink"
//     href="https://www.xing.com/profile/Krzysztof_Zibur"
//     target="_blank"
//     rel="noreferrer noopener"
//   >
//     <i className="fab fa-xing" />
//   </a>
//   <a
//     className="Toolbar_socialLink"
//     href="https://www.linkedin.com/in/krzysztof-zibur-2a596418a/"
//     target="_blank"
//     rel="noreferrer noopener"
//   >
//     <i className="fab fa-linkedin-in" />
//   </a>
//   <a
//     className="Toolbar_socialLink"
//     href="https://twitter.com/KrzysztofZibur"
//     target="_blank"
//     rel="noreferrer noopener"
//   >
//     <i className="fab fa-twitter" />
//   </a>
// </div>;
