import React, { Component } from "react";

import "./Tile.scss";

export class Tile extends Component {
  render() {
    const { icon, title, children } = this.props;
    return (
      <div className="Tile">
        <div className="Tile__header">
          <div className="Tile__icon">{icon}</div>
          <h4 className="Tile__title">{title}</h4>
        </div>
        <div className="Tile__content">{children}</div>
      </div>
    );
  }
}
