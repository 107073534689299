import React, { Component } from "react";

import "./PageTitle.css";

export default class PageTitle extends Component {
  render() {
    const { children } = this.props;
    return <h2 className="PageTitle">{children}</h2>;
  }
}
