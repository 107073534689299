import React, { Component } from "react";

import "./Toolbar.scss";
import logo from "../../assets/logo.svg";

import ScrollWrapper from "../../shared/ScrollWrapper";

export default class Toolbar extends Component {
  state = {
    menuOpen: false
  };

  handleMenuToggle() {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  scrollTo(element) {
    element.preventDefault();
    const hash = element.target.hash.substr(1);

    const targetElement = document.getElementById(hash);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth"
      });
    }

    this.setState({
      menuOpen: false
    });
  }

  handleWindowScroll(e) {
    const items = document.querySelectorAll(".Toolbar__link");
    let fromTop = window.scrollY;

    items.forEach(item => {
      let section = document.querySelector(item.hash);
      if (
        section.offsetTop <= fromTop &&
        section.offsetTop + section.offsetHeight > fromTop
      ) {
        item.classList.add("Toolbar__link--active");
      } else {
        item.classList.remove("Toolbar__link--active");
      }
    });
  }

  render() {
    return (
      <ScrollWrapper onWindowScroll={e => this.handleWindowScroll(e)}>
        <div className="Toolbar">
          <div className="Toolbar__brand">
            <img src={logo} alt="Z" />

            <div className="Toolbar__name">ZIBUR.DEV</div>
          </div>

          <div
            className={
              this.state.menuOpen
                ? "Toolbar__menu Toolbar__menu--open"
                : "Toolbar__menu"
            }
          >
            <a
              className="Toolbar__link Toolbar__link--active"
              onClick={this.scrollTo.bind(this)}
              href="#AboutMe"
            >
              Start
            </a>
            <a
              className="Toolbar__link"
              onClick={this.scrollTo.bind(this)}
              href="#Services"
            >
              Leistungen
            </a>
            <a
              className="Toolbar__link"
              onClick={this.scrollTo.bind(this)}
              href="#Skills"
            >
              Skills
            </a>
            <a
              className="Toolbar__link"
              onClick={this.scrollTo.bind(this)}
              href="#Contact"
            >
              Kontakt
            </a>
          </div>

          <div className="Toolbar__menuToggle">
            <button
              className="Toolbar__menuToggleButton"
              type="button"
              onClick={this.handleMenuToggle.bind(this)}
            >
              <i className="fas fa-bars" />
            </button>
          </div>
        </div>
      </ScrollWrapper>
    );
  }
}
