import React, { Component } from "react";

import "./Skills.scss";

const skillList = [
  "Angular",
  "AngularJS",
  "CSS",
  "Dart",
  "Docker",
  "Firestore",
  "Flutter",
  "Git",
  "GraphQL",
  "HTML",
  "Helm",
  "JavaScript",
  "Kubernetes",
  "MySQL",
  "PostgreSQL",
  "NodeJS",
  "React Native",
  "ReactJS",
  "Redux",
  "SASS",
  "Typescript",
  "Wordpress"
];

export default class Skills extends Component {
  render() {
    const mySkills = skillList.sort().map(skill => <li>{skill}</li>);

    return (
      <section className="Skills" id="Skills">
        <h3 className="Skills__title">Meine Skills</h3>
        <ul className="Skills__content">{mySkills}</ul>
      </section>
    );
  }
}
