import React, { Component } from "react";
import "./TextArea.scss";

export default class TextArea extends Component {
  render() {
    const { label, value, onChange } = this.props;
    return (
      <div className="TextArea">
        <label className="TextArea__label">{label}</label>
        <textarea
          className="TextArea__field"
          placeholder={label}
          rows="2"
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }
}
