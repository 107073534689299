import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export default class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div className="Footer__copyright">
          &copy; 2019 - 2020 Krzysztof Zibur
        </div>
        <div className="Footer__links">
          <Link to="/impressum">Impressum</Link>
          <Link to="/datenschutz">Datenschutz</Link>
        </div>
      </div>
    );
  }
}
