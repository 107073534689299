import React, { Component } from "react";
import Toolbar from "../../modules/toolbar/Toolbar";

import AboutMe from "../../modules/about_me/AboutMe";
import { Services } from "../../services/Services";
import Skills from "../../modules/skills/Skills";
import Contact from "../../contact/Contact";

export default class Index extends Component {
  render() {
    return (
      <div>
        <Toolbar />
        <AboutMe />
        <Services />
        <Skills />
        <Contact />
      </div>
    );
  }
}
