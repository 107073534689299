import { Component } from "react";
import * as _ from "lodash";

export default class ScrollWrapper extends Component {
  handleScroll = _.throttle(event => {
    const { onWindowScroll } = this.props;
    if (onWindowScroll) {
      onWindowScroll(event);
    }
  }, 100);

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
