import React from "react";
import "./App.scss";
import Footer from "./modules/footer/Footer";
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Index from "./pages/index/Index";
import Imprint from "./pages/imprint/Imprint";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <div className="App__content">
                    <Switch>
                        <Route path="/" exact component={Index}/>
                        <Route path="/impressum" component={Imprint}/>
                    </Switch>
                </div>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
