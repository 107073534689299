import React, { Component } from "react";
import "./Services.scss";
import PageTitle from "../shared/PageTitle";
import TileContainer from "./shared/TileContainer";
import { Tile } from "./shared/Tile";

export class Services extends Component {
  render() {
    return (
      <section className="Services" id="Services">
        <PageTitle>Leistungen</PageTitle>
        <div className="Services__content">
          Im folgenden finden Sie eine Übersicht der Leistungen die ich anbiete.
        </div>

        <TileContainer width="50%">
          <Tile
            title="Webentwicklung"
            icon={<i className="fas fa-laptop-code" />}
          >
            <div>
              Ich entwickle individuelle maßgeschneiderte sowie skalierbare
              Webanwendungen. Durch den Einsatz neuester Technologien wird die
              Entwicklungszeit massiv optimiert.
            </div>
          </Tile>

          <Tile
            title="Mobile Entwicklung"
            icon={<i className="fas fa-mobile-alt" />}
          >
            <div>
              Ich erstelle auf Basis von Flutter oder React Native Mobile Apps.
              Die Technologie ermöglicht es mir mit nur einer Code-Basis Android
              und iOS Anwendungen zu erstellen.
            </div>
          </Tile>

          <Tile
            title="Beratung"
            icon={<i className="fas fa-chalkboard-teacher" />}
          >
            <div>
              Durch meine langjährige Erfahrung in der Softwareentwicklung biete
              ich Ihnen gerne mein Wissen an.
            </div>
          </Tile>
        </TileContainer>
      </section>
    );
  }
}
